import React from 'react';
import PropTypes from 'prop-types';
import { DailyChecklistContainer, DateContainer, TextContainer /*ImageContainer*/ } from './styles';
import { findMonth } from '../../utils/methods';
// import DailyTipsV3 from '../DailyTipsV3';
import {ImageUrl} from '../../utils/constants';
import { withTranslation } from 'react-i18next';

const Data = [
  {
    "image": "/images/NewDashboardV2/icon2.png",
    "svg_image": "/images/NewDashboardV2/log_workout.svg",
    "FieldName": "Log Workouts"
  },
  {
    "image": "/images/NewDashboardV2/calender.png",
    "svg_image": "/images/NewDashboardV2/calender.svg",
    "FieldName": "Monthly Tasks"
  }
];
const Days = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat"
]
class DailyChecklist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: ''
    };
  }
   

  handleDailyPopUp = () => {
    this.setState({showModal: 'dailyTip'});
  };
  
 
  hideModal = () => {
    this.setState({showModal: ''});
  };

  redirectToWorkOutHistory = () => {
    const { history } = this.props;
    history.push('/workout-history');
  }

  render() {
    const { /*companyDetails, history, dailyTip, wellnessAssessment, showBiometricPopUp, thanksForTheTipAPI,*/ themesHeader/*, handleshowMonthlyGoals*/, margin} = this.props;
    // const { showModal } = this.state;
    const date = new Date();
    const month =  findMonth(date.getMonth());
    const day = date.getDay();
    const year =  date.getFullYear();
    return(
      themesHeader&&
      <DailyChecklistContainer bgColor={(themesHeader && themesHeader.background_color) ?
        themesHeader.background_color : 0} margin={margin}>
        <div>
          <div className="themeImage">
            <img src={`${ImageUrl}/${themesHeader.image_url}`}/>
          </div>
          <DateContainer>
            <div>
              {this.props.t(Days[day])}
            </div>
            <div>
              {new Date().getDate()}
            </div>
          </DateContainer>
          <TextContainer>
            <div>
              {this.props.t(month)} {year}
            </div>
            <div>
              {this.props.t(themesHeader.theme_text)}
            </div>
          </TextContainer>
          {/* <ImageContainer>
            <img src="/images/NewDashboardV2/arrow_right.png"/>
          </ImageContainer> */}
        </div>
        <div>
          {Data && Data.length > 0 ?
            /*Data.map(() =>*/ (
              // <Card key={index} onClick={(field.FieldName === "Monthly Tasks") ?  handleshowMonthlyGoals :(field.FieldName === "Daily Tips") ?  this.handleDailyPopUp : (field.FieldName === "Log Workouts" && companyDetails && companyDetails.show_log_workout === 1) ? this.props.ShowLogWorkout : (field.FieldName === "Workout History") ? this.redirectToWorkOutHistory : null}>
              //   <ImageWrapper margin={field.FieldName === "Log Workout" ? 1 : 0} width={field.FieldName === "Workout History" || field.FieldName === "Monthly Tasks" ? 1 : 0 }>
              //     <img src={field.svg_image}/>
              //   </ImageWrapper>
              //   <div>
              //     {field.FieldName}
              //   </div>
              // </Card>
              null) : null
          }
        </div>
        {/* <DailyTipsV3
          thanksForTheTipAPI={thanksForTheTipAPI}
          showModal={showModal === 'dailyTip'}
          onClose={this.hideModal}
          dailyTip={dailyTip}
          history={history}
          wellnessAssessment={wellnessAssessment}
          showBiometricPopUp={showBiometricPopUp}
        /> */}
      </DailyChecklistContainer>
    );
  }
}
DailyChecklist.propTypes = {
  companyDetails: PropTypes.object,
  ShowLogWorkout: PropTypes.func,
  dailyTip: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  thanksForTheTipAPI: PropTypes.func,
  wellnessAssessment: PropTypes.object,
  showBiometricPopUp: PropTypes.bool,
  themesHeader: PropTypes.array,
  handleshowMonthlyGoals: PropTypes.func,
  margin: PropTypes.number,
  t: PropTypes.func
}

export default (withTranslation()(DailyChecklist));