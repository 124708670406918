import styled from 'styled-components';
const RegularFont = 'Rubik-Regular';
const MediumFont = 'Rubik-Medium';

const DailyChecklistContainer = styled.div`
width: 100%;
margin-bottom: 25px;
border-radius: 4px;
max-width: 385px;
min-width: 330px;
  > div: first-child {
  background-color: ${({bgColor}) => bgColor ? bgColor : '#FD7175'};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 11px;
  display: flex;
  align-items: center;
	height: 100px;
  border-radius: 4px;
  position: relative;
  .themeImage{
    position: absolute;
    right: 0px;
    opacity: 0.3;
    img{
      width: auto;
      height: 100px;
    }
  }
  }
  > div: last-child {
    margin: 15px 0px;
		display: flex;
		justify-content: center;
    flex-wrap: wrap;
  }
`;
const DateContainer = styled.div`
width: 80px;
background-color: #FFF;
text-align: center;
height: 80px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
border-radius: 3px;
margin-right: 16px;
  > div {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-family: ${RegularFont};
  }

  > div: first-child {

  }

  > div: last-child {
    font-size: 40px;
    font-family: ${MediumFont}
    margin-top: 10px;
  }

	@media (max-width: 766px){
		margin-left: 30px;
	}
`;
const TextContainer = styled.div`
width: 70%;
color: #FFF;
  > div {
    font-size: 24px;
    font-family: ${MediumFont}
  }
  > div: last-child {
    font-size: 18px;
    font-family: ${RegularFont}
		text-decoration: underline;
  }
`;
const Card = styled.div`
display: flex;
align-items: center;
width: calc((100% - 14px) / 2);
border-radius: 6px;
max-width: 195px;
margin-right: 14px;
min-height: 80px;
padding: 12px 12px 12px 0px;
padding: 10px;
cursor: pointer;
background-color: #fff;

  &:nth-child(even) {
    margin-right: 0px;
  }
  &:nth-child(3), &:nth-child(4) {
    margin-bottom: 0px;
  }
  > div {
    font-size: 16px;
		font-family: Rubik;
    line-height: 20px;
    height: 40px;
    width: 68px;
    width: auto;
    height: auto;
    color: #0D4270;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    
    @media (max-width: 1200px) {
      font-size: 14px;
    }
  }

	@media (max-width: 766px) and (min-width: 374px) {
		min-width: 160px;
		max-width: none;
	}
`;
const ImageWrapper = styled.div`
display: flex;
justify-content: center;
margin-right: 13px;
border-radius: 4px;
width: auto;
height: auto;
@media (max-width: 1200px) {
  margin-right: 9px;
}
> img {
	margin-top: ${({margin}) => margin ? '5px' : ''};
	width: 60px;
  height: 60px;
  position: relative;
  @media (max-width: 1200px) {
    width: 45px;
    height: 45px;
  }
}
`;

const ImageContainer = styled.div`
margin-left: 5px;
> img {
  width: 9px
}
`;
export { DailyChecklistContainer, DateContainer, TextContainer, Card, ImageWrapper, ImageContainer };