import styled from 'styled-components';
import BgImg from '/public/images/NewDashboardV2/bgImg.png';
const RegularFont = 'Rubik-Regular';
const MediumFont = 'Rubik-Medium';

const DailyChecklistContainer = styled.div`
width: 100%;
margin-bottom: ${({margin}) => margin ? '0px' : '25px'};
border-radius: 4px;
// max-width: 385px;
// min-width: 330px;
  > div: first-child {
  // background: url(${BgImg});
  background-color: ${({bgColor}) => bgColor ? bgColor : '#FD7175'};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 11px;
  display: flex;
  // width: 385px;
  align-items: center;
	height: 100px;
  border-radius: 4px;
  position: relative;
  .themeImage{
    position: absolute;
    right: 0px;
    opacity: 0.3;
    img{
      width: auto;
      height: 100px;
    }
  }
  }
  > div: last-child {
    margin: 0px 0px;
		display: flex;
		justify-content: center;
    // align-items: center;
    flex-wrap: wrap;
  }
`;
const DateContainer = styled.div`
width: 80px;
background-color: #FFF;
text-align: center;
height: 80px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
border-radius: 3px;
margin-right: 16px;
  > div {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-family: ${RegularFont};
  }

  > div: first-child {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-family: ${RegularFont};
    position: relative;
    top:-3px;
  }

  > div: last-child {
    font-size: 40px;
    font-family: ${MediumFont}
    margin-top: 8px;
  }

	@media (max-width: 766px){
		margin-left: 30px;
	}
`;
const TextContainer = styled.div`
width: 70%;
color: #FFF;
  > div {
    font-size: 18px;
    //color: black;
    font-family: ${MediumFont};
    text-transform: capitalize;
  }
  > div: last-child {
    font-size: 16px;
    font-family: ${RegularFont}
		text-decoration: underline;
  }
`;
const Card = styled.div`
display: flex;
align-items: center;
width: calc((100% - 14px) / 2);
border-radius: 6px;
max-width: 185px;
margin-right: 14px;
min-height: 50px;
padding: 12px 12px 12px 0px;
// border-radius: 4px;
// margin-bottom: 14px;
cursor: pointer;
background-color: #fff;

  &:nth-child(even) {
    margin-right: 0px;
  }
  &:nth-child(3), &:nth-child(4) {
    margin-bottom: 0px;
  }
  > div {
    font-size: 18px;
		font-family: Rubik;
    line-height: 20px;
    height: 40px;
    width: 68px;
  }

	@media (max-width: 766px) and (min-width: 374px) {
		min-width: 150px;
		max-width: none;
	}
`;
const ImageWrapper = styled.div`
// min-width: 45px;
padding: 5px;
// background-color: #f2f2f0;
display: flex;
justify-content: center;
margin-right: 10px;
border-radius: 4px;
> img {
	margin-top: ${({margin}) => margin ? '5px' : ''};
	// width: ${({width}) => width ? 'auto' : '100%'};
	// height: 100%;
	width: 40px;
  height: 40px;
  position: relative;
  bottom: 5px;
}
`;

const ImageContainer = styled.div`
margin-left: 5px;
> img {
  width: 9px
}
`;
export { DailyChecklistContainer, DateContainer, TextContainer, Card, ImageWrapper, ImageContainer };