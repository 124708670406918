import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { DailyChecklistContainer, DateContainer, TextContainer } from './styles';
import { findMonth } from '../../utils/methods';
// const DailyTipsV3 =  React.lazy(() => import('../DailyTipsV3'));
import {ImageUrl} from '../../utils/constants';
import Waiting from '../Waiting';
import DailyTipsV3 from '../DailyTipsV3';
import { withTranslation } from 'react-i18next';

const Data = [
  {
    "image": "/images/NewDashboardV2/icon2.png",
    "svg_image": "/images/NewDashboardV2/log_workout.svg",
    "FieldName": "Log Workouts"
  },
  {
    "image": "/images/NewDashboardV2/calender.png",
    "svg_image": "/images/NewDashboardV2/calender.svg",
    "FieldName": "Monthly Tasks"
  }
];
const Days = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat"
]
class DailyChecklist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: ''
    };
  }
   
  handleDailyPopUp = () => {
    this.setState({showModal: 'dailyTip'});
  };
  
 
  hideModal = () => {
    this.setState({showModal: ''});
  };

  redirectToWorkOutHistory = () => {
    const { history } = this.props;
    history.push('/workout-history');
  }

  render() {
    const { history, dailyTip, wellnessAssessment, showBiometricPopUp, thanksForTheTipAPI, themesHeader/*, handleshowMonthlyGoals*/} = this.props;
    const { showModal } = this.state;
    const date = new Date();
    const month =  findMonth(date.getMonth());
    const day = date.getDay();
    const year =  date.getFullYear();
    return(
      themesHeader &&
      <DailyChecklistContainer bgColor={(themesHeader && themesHeader.length > 0) ?
        themesHeader.background_color : 0}>
        <div>
          <div className="themeImage">
            <img src={`${ImageUrl}/${themesHeader.image_url}`}/>
          </div>
          <DateContainer>
            <div>
              {this.props.t(Days[day])}
            </div>
            <div>
              {new Date().getDate()}
            </div>
          </DateContainer>
          <TextContainer>
            <div>
              {this.props.t(month)} {year}
            </div>
            <div>
              {this.props.t(themesHeader.theme_text)}
            </div>
          </TextContainer>
        </div>
        <div>
          {Data && Data.length > 0 ?
            (null) : null}
        </div>
        <Suspense fallback={<Waiting/>}>
          <DailyTipsV3
            thanksForTheTipAPI={thanksForTheTipAPI}
            showModal={showModal === 'dailyTip'}
            onClose={this.hideModal}
            dailyTip={dailyTip}
            history={history}
            wellnessAssessment={wellnessAssessment}
            showBiometricPopUp={showBiometricPopUp}
          />
        </Suspense>
      </DailyChecklistContainer>
    );
  }
}
DailyChecklist.propTypes = {
  companyDetails: PropTypes.object,
  ShowLogWorkout: PropTypes.func,
  dailyTip: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  thanksForTheTipAPI: PropTypes.func,
  wellnessAssessment: PropTypes.object,
  showBiometricPopUp: PropTypes.bool,
  themesHeader: PropTypes.array,
  handleshowMonthlyGoals: PropTypes.func,
  t: PropTypes.func
}

export default ( withTranslation()(DailyChecklist));